/* You can add global styles to this file, and also import other style files */

@import "./mulish.font.style.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
  /* background: black; */
}
svg {
  display: initial !important;
  vertical-align: initial;
}

body {
  font-family: "Mulish", sans-serif;
}

/* @Header */
.header {
  z-index: 999;
  @apply fixed top-0 left-0 w-full z-50 shadow-lg;
}

/* @Navbar */
.navbar-group {
  @apply py-2 uppercase text-primary;
}
.navbar-group-item {
  @apply text-xs uppercase font-semibold text-primary   group-hover:text-secondary group-hover:underline underline-offset-4;
}

.shop-selector,
.sidebar-wrapper {
  @apply bg-gradient-to-t from-yellow-50 to-white z-50;
}

// .shop-selector .ant-drawer-content,
// .shop-selector .ant-drawer-header,
// .sidebar-wrapper .ant-drawer-content,
// .sidebar-wrapper .ant-drawer-header {
//   // background: transparent;
// }

/* sidebar nav */
.sidebar-nav {
  @apply flex flex-col space-y-2;
}
.sidebar-support-nav {
  @apply flex flex-col space-y-2 border-t pt-4 mt-4;
}

.header-fixed-sm .header {
  @apply fixed w-full  md:relative;
}

.header-fixed-sm .body {
  @apply pt-16    md:pt-0;
}

.wallpaper {
  @apply top-0 left-0 bottom-0 w-screen h-screen fixed -z-10;
}

.footer {
  /* @apply bg-gradient-to-t from-yellow-50 to-white z-50 shadow-slate-200 shadow-sm; */
}
/* livemode switch */
.livemode-switch > .ant-switch {
  @apply bg-slate-400;
}

.livemode-switch > .ant-switch-checked {
  @apply bg-primary;
}

.btn {
  @apply border text-black px-4 py-1 my-2;
}

.btn:hover {
  @apply bg-slate-100 transition-all duration-300;
}

.btn-md {
  @apply text-lg;
}

.btn-lg {
  @apply text-xl;
}

.btn-xl {
  @apply text-2xl;
}

.btn.btn-rounded {
  @apply rounded-full;
}

.btn.btn-shaped {
  @apply rounded-lg;
}

.btn.btn-primary {
  @apply bg-primary text-white border-primary;
}

.btn.btn-primary:hover {
  @apply bg-secondary  text-black border-secondary;
}

.btn.btn-secondary {
  @apply bg-secondary  text-black border-secondary;
}

.btn.btn-secondary:hover {
  @apply bg-primary text-white border-primary;
}

.btn.btn-dark {
  @apply bg-gradient-to-b from-[#1c1c1c] to-[#0c0c0c]  shadow-sm shadow-[#1c1c1c];
  @apply text-primary border-[#0c0c0c];
}
.btn.btn-dark:hover {
  @apply bg-primary text-white border-primary;
}

p:empty::before {
  content: "\00a0"; /* 插入一个空格 */
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: initial;
}

.ant-switch-checked {
  @apply bg-primary;
}
