@import "ng-zorro-antd/ng-zorro-antd.dark.less";

@body-background: #1c1c1c;

/* #Header */
.header-dark {
  @apply bg-gradient-to-b from-[#1c1c1c] to-[#0c0c0c]  shadow-lg shadow-[#1c1c1c];
}

[type="text"],
input:where(:not([type])),
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  background-color: transparent !important;
}
