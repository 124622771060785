/**
 *@Style: Normal
 */

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-Black.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-ExtraLight.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
}

/**
 *@Style Italic
 */

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-BlackItalic.ttf) format("truetype");
  font-weight: 900;
  font-style: Italic;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-ExtraBoldItalic.ttf) format("truetype");
  font-weight: 800;
  font-style: Italic;
}
@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-BoldItalic.ttf) format("truetype");
  font-weight: 700;
  font-style: Italic;
}
@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-SemiBoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: Italic;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-MediumItalic.ttf) format("truetype");
  font-weight: 500;
  font-style: Italic;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: Italic;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: Italic;
}

@font-face {
  font-family: "Mulish";
  src: url(/fonts/Mulish/static/Mulish-ExtraLightItalic.ttf) format("truetype");
  font-weight: 200;
  font-style: Italic;
}
